<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>{{ $t("general.comments") }}</v-toolbar-title>
              </v-app-bar>
              <Table
                :loading="loading"
                @action="action"
                :btns="btns"
                :headers="header"
                :items="comments"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/Table.vue";
import Notify from "../Components/New/Notify.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("cms");
export default {
  name: "News-Comments-Page",
  components: {
    Table,
    Notify,
  },
  data() {
    return {
      loading: true,
      btns: [
        {
          type: "icon",
          text: "approve",
          color: "bg-gradient-success",
          icon: "mdi-check",
          permission: "cms-news-comments-approve",
        },
        {
          type: "icon",
          text: "reject",
          color: "bg-gradient-danger",
          icon: "mdi-close",
          permission: "cms-news-comments-reject",
        },
      ],
      header: [
        { text: this.$i18n.t("sidebar.User"), value: "name", align: "center" },
        {
          text: this.$i18n.t("auth.Email Address"),
          value: "email",
          align: "center",
        },
        {
          text: this.$i18n.t("news.comment"),
          value: "comment",
          align: "center",
        },
        {
          text: this.$i18n.t("news.website"),
          value: "website",
          align: "center",
        },
        {
          text: this.$i18n.t("order.status"),
          value: "status",
          align: "center",
        },
        {
          text: this.$i18n.t("general.Action"),
          value: "btns",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState(["comments"]),
  },
  methods: {
    ...mapActions(["getComments", "rejectComment", "approveComment"]),
    get_comments() {
      this.getComments(this.$route.params.id).then(
        () => {
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch("form/setNotify", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
        }
      );
    },
    action(id, name, item) {
      if (name == "approve") {
        this.approve(item);
      } else if (name == "reject") {
        this.reject(item);
      }
    },
    reject(item) {
      if (item.is_approved == 0) {
        this.$store.dispatch("form/setNotify", {
          msg: this.$i18n.t("this item has already rejected"),
          type: "Danger",
        });
      } else {
        this.$swal({
          title: this.$i18n.t("general.Are you sure?!"),
          text: this.$i18n.t("You won't be able to revert this!"),
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: this.$i18n.t("general.Yes, reject it!"),
          cancelButtonText: this.$i18n.t("general.No, cancel!"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.rejectComment(item.id).then(
              (response) => {
                // console.log(response)
                this.$swal.fire(
                  this.$i18n.t("general.rejected!"),
                  response.data.message,
                  "success"
                );
              },
              (error) => {
                console.log(error);
                this.$swal.fire(
                  this.$i18n.t("general.Error"),
                  this.$i18n.t("general.There error please try again"),
                  "error"
                );
              }
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.fire(
              this.$i18n.t("general.Cancelled"),
              this.$i18n.t("general.Cancelled Reject"),
              "error"
            );
          }
        });
      }
    },
    approve(item) {
      if (item.is_approved == 1) {
        this.$store.dispatch("form/setNotify", {
          msg: this.$i18n.t("this item has already approved"),
          type: "Danger",
        });
      } else {
        this.$swal({
          title: this.$i18n.t("general.Are you sure?!"),
          text: this.$i18n.t("You won't be able to revert this!"),
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: this.$i18n.t("general.Yes, approve it!"),
          cancelButtonText: this.$i18n.t("general.No, cancel!"),
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.approveComment(item.id).then(
              (response) => {
                // console.log(response);
                this.$swal.fire(
                  this.$i18n.t("general.approved!"),
                  response.data.message,
                  "success"
                );
              },
              (error) => {
                console.log(error);
                this.$swal.fire(
                  this.$i18n.t("general.Error"),
                  this.$i18n.t("general.There error please try again"),
                  "error"
                );
              }
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.fire(
              this.$i18n.t("general.Cancelled"),
              this.$i18n.t("general.Cancelled approve"),
              "error"
            );
          }
        });
      }
    },
  },
  mounted() {
    this.get_comments();
    document.title = this.$i18n.t("general.comments");
  },
};
</script>
